<template>
    <div class="Renewal" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small">
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.groupCode"
                                @change="handleChangeGroup"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="机构">
                            <el-select v-model="form.deptCode">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="使用情况">
                            <el-select placeholder="请选择" v-model="form.status">
                                <el-option label="全部" value="" />
                                <el-option label="已使用" value="2" />
                                <el-option label="未使用" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="营业执照名称">
                            <el-input placeholder="营业执照名称" v-model="form.deptLicenseName" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="续费码">
                            <el-input clearable v-model="form.renewalCode" placeholder="请输入续费码" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-left: -85px">
                        <el-form-item label="开始时间" label-width="125px">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 220px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" style="margin-left: -110px">
                        <el-form-item label="结束时间" label-width="125px">
                            <el-date-picker
                                type="date"
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 220px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.system.renewal.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.system.renewal.create')"
                >创建续费码
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.system.renewal.export')"
                >导出
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="机构组" align="center" prop="groupName" />
                <el-table-column label="机构" align="center" prop="deptName" />
                <el-table-column label="营业执照名称" align="center" prop="deptLicenseName" />
                <el-table-column label="续费码" align="center" prop="renewalCode" />
                <el-table-column label="使用状态" align="center" prop="status">
                    <template slot-scope="scope">
                        <span>{{ ['', '未使用', '已使用'][scope.row.status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center" prop="createTime" />
                <el-table-column label="使用时间" align="center" prop="useTime" />
                <el-table-column label="兑换时长(月)" align="center" prop="month" />

                <el-table-column
                    label="操作"
                    width="250"
                    header-align="center"
                    align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.renewal.open')"
                            >查看详情
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            v-if="hasPrivilege('menu.system.renewal.delete') && scope.row.status === 1"
                            @click="handleClose(scope.row)"
                            >删 除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog
            :title="renewalFlag ? '续费码详情' : '创建续费码'"
            :visible.sync="renewalForm.show"
            width="700px"
            @close="close('renewalForm')"
        >
            <el-form label-width="120px" :model="renewalForm" ref="renewalForm" class="demo-dynamic">
                <el-form-item label="创码原因:">
                    <el-radio-group
                        v-model="renewalForm.reason"
                        @change="reasonChange('renewalForm')"
                        :disabled="renewalFlag"
                    >
                        <el-radio :label="1">门店续费</el-radio>
                        <el-radio :label="2">八号地赠送</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="营业执照名册:">
                    <el-select
                        :disabled="renewalFlag"
                        @change="changeLiceseName"
                        size="small"
                        v-model="renewalForm.license_register"
                        value="deptCode"
                        filterable
                    >
                        <el-option
                            v-for="item of license_registerList"
                            :label="item.deptLicenseName"
                            :value="item.deptCode"
                            :key="item.deptCode"
                        />
                    </el-select>
                    <span style="margin-left: 10px; color: #f56c6c" v-if="license_registerList.length == 0">
                        × 无对应门店</span
                    >
                </el-form-item>
                <el-form-item
                    label="机构组:"
                    prop="groupCode"
                    :rules="{ required: true, message: '机构组不能为空', trigger: 'change' }"
                    :inline-message="true"
                >
                    <el-select
                        v-model="renewalForm.groupCode"
                        @change="handleChangeGroupOfCreate"
                        filterable
                        :disabled="renewalFlag"
                        size="small"
                        :loading="loadingDeptGroupFlag"
                    >
                        <el-option
                            v-for="group in create.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="机构:"
                    prop="deptCode"
                    :rules="{ required: true, message: '机构不能为空', trigger: 'change' }"
                    :inline-message="true"
                >
                    <el-select
                        size="small"
                        clearable
                        :disabled="renewalFlag"
                        v-model="renewalForm.deptCode"
                        @change="changeName"
                    >
                        <el-option
                            v-for="dept in create.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <!--                <el-form-item-->
                <!--                    v-if="renewalForm.reason === 1"-->
                <!--                    label="缴费流水:"-->
                <!--                    prop="payNo"-->
                <!--                    :rules="{ required: true, message: '缴费流水不能为空', trigger: 'blur' }"-->
                <!--                    :inline-message="true"-->
                <!--                >-->
                <!--                    <el-input-->
                <!--                        size="small"-->
                <!--                        v-model="renewalForm.payNo"-->
                <!--                        :disabled="renewalFlag"-->
                <!--                        oninput="value=value.replace(/[^\w]/g,'')"-->
                <!--                        maxlength="19"-->
                <!--                    ></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item
                    v-if="renewalForm.reason === 1"
                    label="缴费时间:"
                    prop="payTime"
                    :rules="{ type: 'date', required: true, message: '缴费时间不能为空', trigger: 'change' }"
                    :inline-message="true"
                >
                    <el-date-picker
                        v-model="renewalForm.payTime"
                        :disabled="renewalFlag"
                        size="small"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="renewalForm.reason === 1"
                    label="缴费金额:"
                    prop="payAmount"
                    :rules="{ required: true, message: '缴费金额不能为空', trigger: 'blur' }"
                    :inline-message="true"
                >
                    <el-input
                        size="small"
                        style="width: 120px"
                        v-model="renewalForm.payAmount"
                        @input="fillterNumber"
                        :disabled="renewalFlag"
                    ></el-input
                    >元
                </el-form-item>
                <el-form-item
                    label="兑换时长:"
                    prop="month"
                    :rules="{ required: true, message: '兑换时长不能为空', trigger: 'blur' }"
                    :inline-message="true"
                >
                    <el-input
                        size="small"
                        style="width: 60px"
                        maxlength="3"
                        v-model="renewalForm.month"
                        oninput="value=value.replace(/[^\d]/g,'');if (value[0] === '0') value = parseFloat(value)"
                        :disabled="renewalFlag"
                    ></el-input
                    >月
                </el-form-item>
                <el-form-item
                    v-if="renewalForm.reason === 2"
                    label="赠送原因:"
                    prop="giftReason"
                    :rules="{ required: true, message: '赠送原因不能为空', trigger: 'blur' }"
                    :inline-message="true"
                >
                    <el-input
                        size="small"
                        style="width: 450px"
                        type="textarea"
                        maxlength="200"
                        :autosize="true"
                        v-model="renewalForm.giftReason"
                        :disabled="renewalFlag"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close('renewalForm')" size="small">取 消</el-button>
                <el-button v-if="!renewalFlag" type="primary" @click="handleRowRenewalOk('renewalForm')" size="small"
                    >生 成 续 费 码</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../js/Util';

import UrlUtils from '../../../js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';
// import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
// import StaffCashEdit from './staff/StaffCashEdit';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
// import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Staff',
    mixins: [CheckTableShowColumn],
    // components: { StaffCashEdit, EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                deptCode: '',
                groupCode: '',
                status: '',
                page: 1,
                limit: Util.Limit,
                renewalCode: '',
                startTime: '',
                endTime: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                groups: [],
                deptCodes: [],
            },
            create: {
                groups: [],
                deptCodes: [],
            },
            tableData: [],
            url: {
                page: '/system/renewCode/pageByParam',
                delete: '/system/renewCode/delete',
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
                queryDeptByGroup: '/system/dept/deptTree',
                staffRoleMinWeight: '/system/staff/roleMinWeightRole',
            },
            renewalForm: {
                show: false,
                url: {
                    create: '/system/renewCode/create',
                    view: '/system/renewCode/info_i/',
                },
                reason: 1,
                payNo: '',
                payTime: null,
                payAmount: '',
                month: '',
                giftReason: '',
                deptCode: '',
                groupCode: '',
                license_register: '',
            },
            renewalFlag: false,
            loadingDeptGroupFlag: true,
            staffRoleMinWeight: Number.MAX_VALUE,
            license_registerList: [],
        };
    },
    mounted() {
        this.handleQuery();
        this.handleNowStaffRoleMinWeight();
        this.getCreatList();
        UrlUtils.AllRoles(this, (rst = []) => {
            this.assignRole.metaRoles = rst.filter((e) => {
                //过滤掉超级管理员之类的角色
                return !(Util.isEmpty(e.deptType) && e.weight === 0) && e.code !== 'TDSROL00000000000001';
            });
        });
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.create.groups = rst || [];

            if (this.meta.groups.length == 1) {
                this.form.deptGroupCode = this.meta.groups[0].code;
                this.handleChangeGroup(this.form.deptGroupCode);
            }
            this.loadingDeptGroupFlag = false;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {},
    methods: {
        changeName() {
            this.renewalForm.license_register = this.renewalForm.deptCode;
        },
        changeLiceseName() {
            const _this = this;
            var deptCode = this.renewalForm.license_register;
            _this.$http.get(`/system/dept/info_c/${deptCode}`).then((rst) => {
                this.renewalForm.groupCode = rst.data.data.deptGroupCode;
                this.handleChangeGroupOfCreate(this.renewalForm.groupCode);
                this.renewalForm.deptCode = this.renewalForm.license_register;
            });
        },
        //获取创建时 营业执照名册的list /system/dept/getAllDeptLicenseNames
        getCreatList() {
            const _this = this;
            const _params = {
                deptLicenseName: this.renewalForm.license_register,
            };
            _this.$http.get('/system/dept/getAllDeptLicenseNames', { params: _params }).then((rst) => {
                this.license_registerList = rst.data.data;
            });
        },
        handleQuery() {
            const _this = this;
            const _params = {
                params: _this.form,
            };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleNowStaffRoleMinWeight() {
            UrlUtils.QueryRemote(this, this.url.staffRoleMinWeight, (rst) => {
                this.staffRoleMinWeight = Util.isEmpty(rst) ? Number.MAX_VALUE : rst;
            });
        },
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            if (_this.meta.deptCodes[value]) {
                return _this.meta.deptCodes[value];
            }
            if (!value) {
                _this.meta.deptCodes = [];
                return;
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.deptCodes = rst || [];
                // if (_this.meta.deptCodes.length == 1) {
                //     _this.form.deptCode = _this.meta.deptCodes[0].code;
                // }
            });
        },
        handleChangeGroupOfCreate(value, type) {
            const _this = this;
            this.renewalForm.deptCode = '';
            if (_this.create.deptCodes[value]) {
                return _this.create.deptCodes[value];
            }
            if (!value) {
                _this.create.deptCodes = [];
                return;
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.create.deptCodes = rst || [];
            });
        },
        handleCreate() {
            this.renewalForm.show = true;
            this.renewalFlag = false;
            this.renewalForm.groupCode = '';
            this.renewalForm.deptCode = '';
            this.renewalForm.reason = 1;
            // this.renewalForm.payNo = '';
            this.renewalForm.payTime = null;
            this.renewalForm.payAmount = '';
            this.renewalForm.month = '';
            this.renewalForm.giftReason = '';
            this.renewalForm.license_register = '';
            this.create.deptCodes = [];
        },
        handleExport() {
            UrlUtils.Export(this, '续费码', '/system/renewCode/export', this.form);
        },
        handleEdit(row) {
            const _this = this;
            UrlUtils.QueryRemote(_this, _this.renewalForm.url.view + row.id, (rst) => {
                UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + rst.groupCode, (rst) => {
                    _this.create.deptCodes = rst || [];
                });
                _this.renewalForm.deptCode = rst.deptCode;
                _this.renewalForm.groupCode = rst.groupCode;
                _this.renewalForm.reason = rst.reason;
                // _this.renewalForm.payNo = rst.payNo;
                _this.renewalForm.payTime = new Date(rst.payTime);
                _this.renewalForm.payAmount = MoneyUtils.moneyToFour(rst.payAmount);
                _this.renewalForm.month = rst.month;
                _this.renewalForm.giftReason = rst.giftReason;
                _this.renewalForm.license_register = rst.deptLicenseName;
                _this.renewalForm.show = true;
                _this.renewalFlag = true;
            });
        },
        reasonChange(formName) {
            this.$refs[formName].resetFields();
            this.renewalForm.groupCode = '';
            this.renewalForm.deptCode = '';
            // this.renewalForm.payNo = '';
            this.renewalForm.payTime = null;
            this.renewalForm.payAmount = '';
            this.renewalForm.month = '';
            this.renewalForm.giftReason = '';
        },
        fillterNumber(e) {
            e = e
                .replace(/[^\d.]/g, '') //将非数字和点以外的字符替换成空
                .replace(/^\./g, '') //验证第一个字符是数字而不是点
                .replace(/\.{2,}/g, '.') //出现多个点时只保留第一个
                .replace('.', '$#$') // 1、将数字的点替换成复杂字符$#$
                .replace(/\./g, '') // 2、将字符串的点直接清掉
                .replace('$#$', '.') // 3、将复杂字符再转换回点
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
                .replace(/^\D*(\d{0,8}(?:\.\d{0,2})?).*$/g, '$1'); //只能输入八位整数
            if (e.indexOf('.') < 0 && e != '') {
                e = parseFloat(e);
            } else if (e.indexOf('.') > 1 && e[0] === '0') {
                e = parseFloat(e);
            }
            this.$nextTick(() => {
                this.$set(this.renewalForm, 'payAmount', e);
            });
        },
        close(formName) {
            this.$refs[formName].resetFields();
            this.renewalForm.show = false;
            // this.meta.deptCodes = [];
        },
        handleRowRenewalOk(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const _this = this;
                    const _params = {
                        groupCode: _this.renewalForm.groupCode,
                        deptCode: _this.renewalForm.deptCode,
                        reason: _this.renewalForm.reason,
                        // payNo: _this.renewalForm.payNo,
                        payTime: _this.renewalForm.payTime,
                        payAmount: MoneyUtils.moneyToFour(_this.renewalForm.payAmount * 100000000),
                        month: _this.renewalForm.month,
                        giftReason: _this.renewalForm.giftReason,
                        deptLicenseName: _this.renewalForm.deptLicenseName,
                    };
                    UrlUtils.PostRemote(_this, _this.renewalForm.url.create, _params, null, () => {
                        _this.renewalForm.groupCode = '';
                        _this.renewalForm.deptCode = '';
                        _this.meta.deptCodes = [];
                        _this.handleQuery();
                        _this.$message.success('新建成功');
                        _this.renewalForm.show = false;
                    });
                } else {
                    return false;
                }
            });
        },
        handleClose(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
    },
    filters: {
        roles(roles) {
            return roles ? roles.map((r) => r.name).join(',') : '';
        },
    },
};
</script>

<style scoped>
.Renewal .el-form-item {
    margin-bottom: 0;
}
div /deep/ .el-form-item__error {
    left: 0;
}
</style>
